import React, { useEffect } from "react";
import '../style/PricingPackage.css';

const PricingPackages = () => {
    const learnMore = (packageType) => {
        // console.log(`Learn more about: ${packageType}`);
        // Implement actual navigation or action logic here
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };

        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("animate__animated");
                    entry.target.classList.add(entry.target.dataset.animate);
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        document.querySelectorAll(".animate-on-scroll").forEach((el) => {
            observer.observe(el);
        });

        // Cleanup observer on unmount
        return () => observer.disconnect();
    }, []);

    return (
        <section className="pricing-packages">

            <div className="packages" id="pricing">
                <a href="/signin" target="_blank" rel="noopener noreferrer" className="package">
                    <div onClick={() => learnMore("Basic")}>
                        <h3>Basic Plan</h3>
                        <h4>₦250/month</h4>
                        <h5>What's Included:</h5>
                        <ul>
                            <li>Item registration</li>
                            <li>Reporting lost items</li>
                            <li>Access to the finders' community</li>
                            <li>LFReturnMe Pick Up Location</li>
                        </ul>
                    </div>
                </a>

                <a href="/signin" target="_blank" rel="noopener noreferrer" className="package">
                    <div onClick={() => learnMore("Standard")}>
                        <h3>Standard Plan</h3>
                        <h4>₦300/month</h4>
                        <h5>What's Included:</h5>
                        <ul>
                            <li>All Basic Plan features</li>
                            <li>Delivery of Found item Inter-state</li>
                            <li>Priority customer support</li>
                            <li>Enhanced recovery support</li>
                        </ul>
                    </div>
                </a>

                <a href="/signin" target="_blank" rel="noopener noreferrer" className="package">
                    <div onClick={() => learnMore("Premium")}>
                        <h3>Premium Plan</h3>
                        <h4>₦1000/month</h4>
                        <h5>What's Included:</h5>
                        <ul>
                            <li>All Basic & Standard Plan features</li>
                            <li>Delivery of Items Nationwide</li>
                            <li>Affidavit</li>
                            <li>Regular updates and notifications</li>
                        </ul>
                    </div>
                </a>

                <a href="/signin" target="_blank" rel="noopener noreferrer" className="package">
                    <div onClick={() => learnMore("InternationalPassport")}>
                        <h3>LFReturnMe International Passport Subscription Plan</h3>
                        <h4>₦1,500/month</h4>
                        <h5>What's Included:</h5>
                        <ul>
                            <li>Digital Registration</li>
                            <li>Instant Lost Item Alerts</li>
                            <li>Dedicated Priority Support</li>
                            <li>Document Verification</li>
                            <li>Secure Document Pick-Up/Delivery</li>
                        </ul>
                    </div>
                </a>

                {/* Custom Plan for Large Businesses */}
                <div className="package">
    <div onClick={() => learnMore("Corporate")}>
        <h3>Corporate Plan</h3>
        <h4>Contact Us</h4>
        <h5>What's Included:</h5>
        <ul>
            <li>Custom solutions for large businesses</li>
            <li>Personalized support and consultation</li>
            <li>Volume-based pricing</li>
            <li>Enterprise-level security and recovery options</li>
        </ul>
        <a href="mailto:info@lfreturnme.com?subject=CORPORATE%20SUBSCRIPTION" className="cta-button">
            Contact Customer Care
        </a>
    </div>
</div>


            </div>
        </section>
    );
};

export default PricingPackages;
